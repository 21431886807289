import { Component, EventEmitter, Output, input, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { Plan } from './model';
import { PlanCard } from './plan';
@Component({
  selector: 'plan-list',
  standalone: true,
  imports: [MatButtonToggleModule, FormsModule, PlanCard],
  template: `
    <div class="plan-list-container">
      <div class="frequency-selector">
        <mat-button-toggle-group
          (ngModelChange)="setAnually($event)"
          [ngModel]="anually()"
          aria-label="Anually">
          <mat-button-toggle [value]="false">Monthly</mat-button-toggle>
          <mat-button-toggle [value]="true">Annual</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
      <div class="plans-container">
        @for (plan of plans(); track plan.id; let index = $index) {
          <plan-card
            [plan]="plan"
            [isSelected]="index === 1"
            [isAnnually]="anually()"
            [popular]="index === 1"
            (buyClick)="onBuyClick($event)"></plan-card>
        }
      </div>
    </div>
  `,
  styles: [
    `
      .plan-list-container {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding: 0 1rem;

        .frequency-selector {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .plans-container {
          display: flex;
          justify-content: center;
          gap: 1rem;
        }
      }
    `,
  ],
})
export class PlanList {
  plans = input.required<Plan[]>();
  anually = signal<boolean>(true);
  @Output() selectedPlan = new EventEmitter<Plan>();
  @Output() annualSelect = new EventEmitter<boolean>();

  setAnually(value: boolean) {
    this.annualSelect.emit(value);
    this.anually.set(value);
  }

  onBuyClick(plan: Plan) {
    this.selectedPlan.emit(plan);
  }
}
