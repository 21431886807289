import { Injectable, inject } from '@angular/core';
import { Observable, map } from 'rxjs';
import { Plan } from './model';
import { HttpClient } from '@angular/common/http';
import { BASE_API_URL } from '@trueleap/ui-tokens';
@Injectable({
  providedIn: 'root',
})
export class PlanService {
  #http = inject(HttpClient);
  #baseURl = inject(BASE_API_URL);

  get resourceURI(): string {
    return `${this.#baseURl}/plan`;
  }

  fetchAllPlans(): Observable<Plan[]> {
    return this.#http
      .get<{
        data: Plan[];
      }>(this.resourceURI)
      .pipe(
        map(r => {
          return r.data.concat({
            id: 'free',
            name: 'Free',
            description: 'Free plan',
            priceYearly: 0,
            priceMonthly: 0,
            features: [],
            isPublic: true,
            isActive: true,
            provider: 'trueleap',
          });
        }),
        map(r => r.sort((a, b) => a.priceYearly - b.priceYearly))
      );
  }

  fetchPlan(id: string): Observable<Plan> {
    return this.#http
      .get<{
        data: Plan;
      }>(`${this.resourceURI}/${id}`)
      .pipe(map(r => r.data));
  }
}
