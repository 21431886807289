import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MtxDrawerRef } from '@ng-matero/extensions/drawer';
import { NotificationStore } from './notification.store';
import { MatTabsModule } from '@angular/material/tabs';
import { MatListModule } from '@angular/material/list';
import { MatDividerModule } from '@angular/material/divider';
import { lastValueFrom } from 'rxjs';
import { NotificationService } from './notification.service';
import { UserNotificationDto } from '@trueleap-plus/dto';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'landing-notification-sidesheet',
  standalone: true,
  imports: [
    MatButtonModule,
    MatIconModule,
    MatTabsModule,
    MatListModule,
    MatDividerModule,
    DatePipe,
  ],
  template: `
    <div class="notification-sidesheet">
      <div class="notification-header">
        <h6 class="mat-title-small">Notifications</h6>
        <button mat-icon-button (click)="onClose()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <div class="notification-body">
        <mat-tab-group>
          <mat-tab label="Unread">
            <div class="notification-list">
              <mat-list>
                @for (notification of unread(); track notification.id) {
                  <mat-list-item (click)="markAsRead(notification)" lines="3">
                    <mat-icon matListItemIcon>notifications_active</mat-icon>
                    <div matListItemTitle>
                      {{ notification.notification?.title }}
                    </div>
                    <div matListItemLine>
                      {{ notification.notification?.description }}
                    </div>
                    <span matListItemLine>
                      {{
                        notification.notification?.createdAt | date: 'medium'
                      }}
                    </span>
                  </mat-list-item>
                  <mat-divider></mat-divider>
                } @empty {
                  <mat-list-item>
                    <mat-icon matListItemIcon>notifications_none</mat-icon>
                    <div matListItemTitle>No unread notifications</div>
                  </mat-list-item>
                }
              </mat-list>
            </div>
          </mat-tab>
          <mat-tab label="All">
            <div class="notification-list">
              <mat-list>
                @for (notification of notifications(); track notification.id) {
                  <mat-list-item lines="3">
                    <mat-icon matListItemIcon> circle_notifications </mat-icon>
                    <div matListItemTitle>
                      {{ notification.notification?.title }}
                    </div>
                    <div matListItemLine>
                      {{ notification.notification?.description }}
                    </div>

                    <span matListItemLine>
                      {{
                        notification.notification?.createdAt | date: 'medium'
                      }}
                    </span>
                  </mat-list-item>
                  <mat-divider></mat-divider>
                }
              </mat-list>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  `,
  styles: [
    `
      .notification-sidesheet {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        overflow: auto;
        .notification-header {
          padding: 0.75rem 1rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
          position: sticky;
          top: 0;
          border-bottom: 1px solid var(--surface-container-highest);

          h6 {
            margin: 0;
            padding: 0;
          }
        }

        .notification-body {
          h6 {
            margin: 0;
            padding: 0;
          }

          padding: 0.5rem 1rem;
          display: flex;
          flex-grow: 1;
          flex-direction: column;
          gap: 1rem;

          .notification-list {
          }
        }
      }
    `,
  ],
})
export class NotificationSidesheetComponent {
  notificationStore = inject(NotificationStore);
  notifications = inject(NotificationStore).notifications;
  unread = inject(NotificationStore).unreadNotifications;
  notificationService = inject(NotificationService);
  constructor(public drawerRef: MtxDrawerRef<NotificationSidesheetComponent>) {}
  onClose(): void {
    this.drawerRef.dismiss();
  }

  async markAsRead(notification: UserNotificationDto) {
    await lastValueFrom(
      this.notificationService.markNotificationAsRead(
        notification.notification_id
      )
    );
    this.notificationStore.markNotificationAsRead(notification.notification_id);
  }
}
