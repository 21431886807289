import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'landing-footer',
  standalone: true,
  imports: [MatInputModule, MatFormFieldModule, MatButtonModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="container">
      <div class="logo-and-address">
        <div class="logo">
          <img src="assets/logos/vertical-logo.svg" alt="TrueLeap logo" />
        </div>
        <div class="address mat-body-small">
          <p>Global HQ</p>
          <p>1801 Main St 10F</p>
          <p>Houston, TX 77002, USA</p>
          <p>info&#64;trueleap.io</p>
        </div>
      </div>

      <div class="quick-links-container">
        <p class="mat-title-large">Quick Links</p>
        <ul class="quick-links">
          <li><a class="mat-body-large" href="#">About</a></li>
          <li><a class="mat-body-large" href="#">Contact</a></li>
          <li><a class="mat-body-large" href="#">Privacy Policy</a></li>
          <li><a class="mat-body-large" href="#">Terms of Service</a></li>
        </ul>
      </div>

      <div class="resources-container">
        <p class="mat-title-large">Resources</p>
        <ul class="resources">
          <li><a class="mat-body-large" href="#">Blog</a></li>
          <li><a class="mat-body-large" href="#">FAQ</a></li>
          <li><a class="mat-body-large" href="#">Support</a></li>
        </ul>

        <div class="social-media">
          <a href="#">
            <img src="assets/icons/logo-set.svg" alt="" />
          </a>
        </div>
      </div>

      <div class="subscribe-container">
        <p class="mat-title-medium">Subscribe to our newsletter</p>
        <div class="subscribe">
          <mat-form-field appearance="outline">
            <input matInput placeholder="Email" type="email" />
          </mat-form-field>
          <div>
            <button mat-flat-button color="primary">Subscribe</button>
          </div>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      @use '@angular/material' as mat;
      @import 'theme';

      .container {
        padding: 3.125rem 6.25rem;
        display: flex;
        color: #f8f2f2;

        .logo-and-address {
          flex-grow: 0.2;
          .address {
            margin-top: 1.25rem;
            p {
              margin: 0;
              padding: 0;
            }
          }
        }

        .quick-links-container {
          flex-grow: 0.2;
          .quick-links {
            display: flex;
            padding: 1rem;
            flex-direction: column;
            justify-content: space-between;

            li {
              a {
                color: #f8f2f2;
              }
            }
          }
        }

        .resources-container {
          flex-grow: 0.2;
          .resources {
            display: flex;
            padding: 1rem;
            flex-direction: column;
            justify-content: space-between;
            li {
              a {
                color: #f8f2f2;
              }
            }
          }
          .social-media {
            display: flex;
            justify-content: space-between;
            margin-top: 1rem;
            min-width: 6rem;
            height: 1.5rem;
          }
        }

        .subscribe-container {
          flex-grow: 0.4;
          p {
            margin: 0;
          }
          background: #2e2e42;
          padding: 1.25rem;
          .subscribe {
            margin-top: 1.25rem;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            @include mat.form-field-density(-4);

            ::ng-deep.mat-mdc-text-field-wrapper.mdc-text-field.mdc-text-field--outlined.mdc-text-field--no-label {
              background: #f8f2f2;
              border-radius: 0.25rem;
            }
          }
        }
      }
    `,
  ],
})
export class Footer {}
