import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'landing-know-us',
  imports: [MatButtonModule],
  standalone: true,
  template: `
    <div class="know-us-container">
      <div class="know-us-left-container">
        <div class="know-us-left-image">
          <img src="assets/know-us/left/left-left.svg" />
        </div>

        <div class="know-us-center-image">
          <img src="assets/know-us/left/left-center.svg" />
        </div>

        <div class="know-us-right-image">
          <img src="assets/know-us/left/left-right.svg" />
        </div>
      </div>

      <div class="know-us-right-container">
        <div class="know-us-right-top-image">
          <img src="assets/know-us/right/right-top.svg" />
        </div>

        <div class="know-us-right-text-container">
          <p class="mat-body-2">WHO WE ARE</p>

          <p class="mat-display-small">We Offer The Best Carrier</p>

          <ul class="know-us-list-container">
            <li class="know-us-list-item">
              <img src="assets/know-us/right/badge.svg" />
              <div class="know-us-list-item-text">
                <p class="mat-body-2">Industry Expert Instructor</p>
                <p class="mat-caption">
                  Online learning has become increasingly popular in recent
                  years, offering a flexible and convenient way for learning.
                </p>
              </div>
            </li>
            <li class="know-us-list-item">
              <img src="assets/know-us/right/badge.svg" />
              <div class="know-us-list-item-text">
                <p class="mat-body-2">Industry Expert Instructor</p>
                <p class="mat-caption">
                  Online learning has become increasingly popular in recent
                  years, offering a flexible and convenient way for learning.
                </p>
              </div>
            </li>

            <li class="know-us-list-item">
              <img src="assets/know-us/right/badge.svg" />
              <div class="know-us-list-item-text">
                <p class="mat-body-2">Industry Expert Instructor</p>
                <p class="mat-caption">
                  Online learning has become increasingly popular in recent
                  years, offering a flexible and convenient way for learning.
                </p>
              </div>
            </li>
          </ul>

          <button mat-flat-button color="accent">Know About Us</button>
        </div>

        <div class="know-us-right-bottom-image">
          <img src="assets/know-us/right/right-bottom.svg" />
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      .know-us-container {
        padding: 7rem 0;
        display: flex;

        max-width: 1080px;
        margin: 0 auto;

        .know-us-left-container {
          flex: 0.4;
          display: flex;

          .know-us-left-image {
            padding-top: 5.5rem;
            z-index: 1;
            img {
              width: 8rem;
              height: 8rem;
            }
          }

          .know-us-center-image {
            z-index: 2;
            padding-top: 1.5rem;
            padding-bottom: 5.25rem;
            margin-left: -1.75rem;

            img {
              width: 18rem;
              height: 25rem;
              object-fit: cover;
            }
          }

          .know-us-right-image {
            z-index: 3;
            margin-left: -3rem;
            padding-top: 9.375rem;
            img {
              width: 6rem;
              height: 10rem;
              object-fit: cover;
            }
          }
        }

        .know-us-right-container {
          flex: 0.6;
          position: relative;
          padding-left: 1rem;
          padding-right: 2rem;

          .know-us-right-top-image {
            position: absolute;
            top: 3rem;
            right: 0;

            img {
              width: 5rem;
              height: 4.4rem;
            }
          }

          .know-us-right-bottom-image {
            position: absolute;
            bottom: -2rem;
            right: 6.25rem;

            img {
              width: 2rem;
              height: 4.25rem;
            }
          }

          .know-us-right-text-container {
            padding-top: 1rem;
            padding-right: 2rem;
            padding-left: 2rem;

            .mat-headline-4 {
              margin-bottom: 2rem;
            }
            .know-us-list-container {
              list-style: none;
              padding: 0;
              margin: 0;

              .know-us-list-item {
                display: flex;
                gap: 1.5rem;
                align-items: flex-start;
                margin-bottom: 2.25rem;

                img {
                  width: 3.75rem;
                  height: 3.75rem;
                }

                p {
                  margin-top: 0;
                  margin-bottom: 0.75rem;
                }
              }
            }
          }
        }
      }
    `,
  ],
})
export class LandingKnowUs {}
