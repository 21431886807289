import { Component, EventEmitter, Output, input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { Plan } from './model';

@Component({
  selector: 'plan-card',
  imports: [MatButtonModule, MatIconModule, MatChipsModule],

  standalone: true,
  template: `
    @if (plan(); as plan) {
      <div
        class="trlp-pricing-card-container "
        [class.trlp-pricing-card-selected]="isSelected()">
        <div class="trlp-pricing-card-header">
          <div class="trlp-pricing-card-title">
            <h3>{{ plan.name }}</h3>
          </div>

          @if (popular()) {
            <div class="badge-container">
              <mat-chip-set>
                <mat-chip highlighted [color]="'primary'">Popular</mat-chip>
              </mat-chip-set>
            </div>
          }
        </div>
        <div class="trlp-pricing-card-body">
          <div class="trlp-pricing-card-description">
            <p title="{{ plan.description }}">
              {{ plan.description }}
            </p>
          </div>
          <div class="trlp-pricing-card-price">
            @if (isAnnually()) {
              <h1>\${{ plan.priceYearly }}</h1>
              <span class="mat-body-1">/month</span>
            } @else {
              <h1>\${{ plan.priceMonthly }}</h1>
              <span class="mat-body-1">/month</span>
            }
          </div>

          <div class="trlp-pricing-card-features">
            @for (feature of plan.features; track feature) {
              <div class="trlp-pricing-card-feature">
                <span class="trlp-pricing-card-feature-icon">
                  <mat-icon color="primary">check</mat-icon>
                </span>
                <span class="trlp-pricing-card-feature-name">
                  {{ feature }}
                </span>
              </div>
            }
          </div>
          <div class="trlp-pricing-card-button">
            <button
              mat-raised-button
              color="primary"
              (click)="buyClick.emit(plan)">
              Get started
            </button>
          </div>
        </div>
        <div class="trlp-pricing-card-footer"></div>
      </div>
    }
  `,
  styles: [
    `
      @use '@angular/material' as mat;
      @import 'responsive';

      :host {
        --trlp-card-padding: 2rem;
        --trlp-card-border-radius: 0.75rem;
        --trlp-card-border-width: 1px;
        --trlp-card-border-color: #c0c0c0;
        --trlp-card-box-shadow: none;
        display: flex;
      }

      .trlp-pricing-card-container {
        padding: var(--trlp-card-padding);
        border: var(--trlp-card-border-width) solid
          var(--trlp-card-border-color);
        border-radius: var(--trlp-card-border-radius);
        box-shadow: var(--trlp-card-box-shadow);
        display: flex;
        flex-direction: column;
        transition: all 0.2s ease-in-out;
        width: 20rem;
        max-width: 20rem;

        .trlp-pricing-card-header {
          display: flex;
          align-items: start;
          justify-content: space-between;
          min-height: 3rem;

          .trlp-pricing-card-title {
            h3 {
              margin: 0;
            }
          }
        }

        &.trlp-pricing-card-selected {
          border-color: #1672ec;

          .trlp-pricing-card-title > h3 {
            color: #1672ec;
          }

          box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);

          .trlp-pricing-card-price {
            color: #1672ec;
          }

          .trlp-pricing-card-button {
            button {
              background-color: #1672ec;
              color: #fff;
            }
          }
        }
      }
      .trlp-pricing-card-body {
        flex-grow: 1;
        margin-top: 1.5rem;
        display: flex;
        flex-direction: column;
        .trlp-pricing-card-description {
          p {
            margin: 0;
            max-height: 4rem;
            text-overflow: ellipsis;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }
      }
      .trlp-pricing-card-price > h1 {
        letter-spacing: -0.05em;
        font-size: 2.25rem;
        line-height: 2.5rem;
        font-weight: 700;
        font-variant-numeric: tabular-nums;
        margin: 0;
      }

      .trlp-pricing-card-price {
        display: flex;
        align-items: end;
        gap: 0.25rem;

        > span.mat-body-1 {
          margin: 0;
          margin-bottom: 0.25rem;
        }
      }

      .trlp-pricing-card-description {
        min-height: 4rem;
      }

      .trlp-pricing-card-features {
        margin-top: 1.5rem;
        flex-grow: 1;
      }

      .trlp-pricing-card-feature {
        display: flex;
        align-items: center;
        gap: 0.75rem;

        .trlp-pricing-card-feature-icon {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .trlp-pricing-card-button {
        margin-top: 1.5rem;

        button {
          width: 100%;
        }
      }
    `,
  ],
})
export class PlanCard {
  plan = input.required<Plan>();
  isAnnually = input<boolean>(true);
  isSelected = input<boolean>(false);
  popular = input<boolean>(false);

  @Output() buyClick = new EventEmitter<Plan>();
}
