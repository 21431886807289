import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { UserNotificationDto } from '@trueleap-plus/dto';
import { BASE_API_URL } from '@trueleap/ui-tokens';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  baseURL = inject(BASE_API_URL);
  http = inject(HttpClient);

  getNotifications() {
    return this.http.get<UserNotificationDto[]>(`${this.baseURL}/notification`);
  }

  markNotificationAsRead(notificationId: string) {
    return this.http.post<UserNotificationDto>(
      `${this.baseURL}/notification/read/${notificationId}`,
      {}
    );
  }
}
