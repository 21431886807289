import { Component } from '@angular/core';
import { ContentLoaderModule } from '@ngneat/content-loader';
@Component({
  selector: 'plan-loading',
  imports: [ContentLoaderModule],
  template: `
    <div class="container">
      @for (loader of loaderList; track loader) {
        <bullet-list-content-loader></bullet-list-content-loader>
      }
    </div>
  `,
  styles: [
    `
      .container {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-auto-rows: 1fr;
        grid-gap: 1.5rem;
      }
    `,
  ],
  standalone: true,
})
export class PlanLoading {
  loaderList = [1, 2, 3];
}
