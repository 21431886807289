import { Component, input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { RouterLinkActive, RouterLinkWithHref } from '@angular/router';
import { MtxTooltip } from '@ng-matero/extensions/tooltip';
import { ExternalIcon } from '@trueleap/ui-kit';
import { NavLinkItem } from './type';
@Component({
  imports: [
    RouterLinkWithHref,
    MatButtonModule,
    MatMenuModule,
    RouterLinkActive,
    MatIconModule,
    ExternalIcon,
    MtxTooltip,
  ],
  standalone: true,
  template: `
    @if (navlinkItem().children) {
      <button
        #t="matMenuTrigger"
        [matMenuTriggerFor]="menu"
        class="trueleap-nav-btn"
        mat-button>
        {{ navlinkItem().name }}
        @if (t.menuOpen) {
          <mat-icon iconPositionEnd>expand_less</mat-icon>
        } @else {
          <mat-icon iconPositionEnd> expand_more</mat-icon>
        }
      </button>
      <mat-menu class="trueleap-menu" #menu="matMenu">
        @for (child of navlinkItem().children; track child.name) {
          <a
            mat-menu-item
            routerLink="{{ child.url }}"
            routerLinkActive="active-nav-link"
            [routerLinkActiveOptions]="{ exact: true }"
            color="primary">
            {{ child.name }}
          </a>
        }
      </mat-menu>
    } @else {
      <a
        routerLink="{{ navlinkItem().url }}"
        mat-button
        [disabled]="navlinkItem().name === 'Mentors'"
        [routerLinkActiveOptions]="{ exact: true }"
        routerLinkActive="active-nav-link">
        @if (navlinkItem().name === 'Mentors') {
          <mat-icon mtxTooltip="Coming soon" iconPositionEnd> timer </mat-icon>
        }
        {{ navlinkItem().name }}
      </a>
    }
  `,
  selector: `trlp-nav-link-item`,
  styles: [
    `
      .active-nav-link {
        background-color: rgba(26, 27, 31, 0.08);
      }
    `,
  ],
})
export class NavLinkItemComponent {
  navlinkItem = input.required<NavLinkItem>();
}
