import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'landing-hero',
  standalone: true,
  template: `
    <div class="landing-page-hero-container">
      <div class="hero-container-left">
        <div class="hero-text-container-left-top">
          <img src="assets/hero/hero-left-top.svg" />
        </div>
        <div class="hero-container-text-container">
          <h4 class="mat-h4 hero-text-title">better learning future with us</h4>
          <p class="mat-display-medium hero-text-main-title">
            Education is about
          </p>
          <p class="mat-display-medium hero-text-main-title">Academic</p>
          <p class="mat-display-medium hero-text-main-title">Excellence</p>

          <p class="hero-text-description">
            Empower yourself with the knowledge and skills gained through online
            education and best instructors.
          </p>
        </div>

        <div class="hero-left-button-container">
          <button mat-flat-button color="accent">Get Started Today</button>
        </div>

        <div class="hero-text-container-left-bottom">
          <img src="assets/hero/hero-left-bottom.svg" />
        </div>
      </div>

      <div class="hero-container-right">
        <div class="hero-container-right-top">
          <img src="assets/hero/hero-right-top.svg" />
        </div>
        <div class="hero-right-image-container">
          <div class="hero-right-image-top">
            <img src="assets/hero/right/images/top.svg" />
          </div>

          <div class="hero-right-image-center">
            <img src="assets/hero/right/images/center.svg" />
          </div>

          <div class="hero-right-image-bottom">
            <img src="assets/hero/right/images/bottom.svg" />
          </div>
        </div>

        <div class="hero-container-right-bottom">
          <img src="assets/hero/hero-right-bottom.svg" />
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      .landing-page-hero-container {
        padding-top: 3.5rem;
        padding-bottom: 6rem;
        display: flex;

        max-width: 1080px;
        margin: 0 auto;

        .hero-container-left {
          padding-left: 10rem;
          flex: 1;
          position: relative;
          .hero-text-container-left-top {
            position: absolute;
            top: 2rem;
            left: 4rem;

            img {
              width: 4.5rem;
              height: 4rem;
            }
          }

          .hero-container-text-container {
            padding-top: 5rem;
            .hero-text-title {
              text-transform: uppercase;
              margin-bottom: 1rem;
            }

            .hero-text-main-title {
              text-transform: capitalize;
              margin-bottom: 1rem;
              color: var(--primary-color);
            }

            .hero-text-description {
              padding: 1rem 0;
            }
          }

          .hero-left-button-container {
            padding: 2rem 0;
            margin-bottom: 4.75rem;
          }

          .hero-text-container-left-bottom {
            position: absolute;
            bottom: 0;
            left: 0;
            img {
              width: 3rem;
              height: 3rem;
            }
          }
        }

        .hero-container-right {
          flex: 1;
          position: relative;

          .hero-container-right-top {
            position: absolute;
            top: 0;
            right: 0;
            img {
              width: 3rem;
              height: 3rem;
            }
          }

          .hero-container-right-bottom {
            position: absolute;
            bottom: 0;
            right: 0;
            img {
              width: 3rem;
              height: 3rem;
            }
          }

          .hero-right-image-container {
            position: relative;
            width: 100%;
            height: 100%;
            .hero-right-image-top {
              position: absolute;
              top: 4rem;
              right: 11rem;
              z-index: 3;
              img {
                width: 6rem;
                height: 6rem;
              }
            }

            .hero-right-image-center {
              position: absolute;
              top: 8rem;
              right: 0.5rem;
              z-index: 3;
              img {
                width: 15rem;
                height: 16rem;
              }
            }

            .hero-right-image-bottom {
              position: absolute;
              bottom: 5rem;
              left: 0;
              z-index: 2;
              img {
                width: 20rem;
                height: 20rem;
              }
            }
          }
        }
      }
    `,
  ],
  imports: [MatIconModule, MatButtonModule],
})
export class HeroComponent {}
