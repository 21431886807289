import { Plan } from './model';
import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';
type PlanState = {
  selectedPlan?: Plan;
  isAnually?: boolean;
};

const initialState: PlanState = {};

export const PlanStore = signalStore(
  {
    providedIn: 'root',
  },
  withState(initialState),
  withMethods(store => ({
    selectPlan(plan: Plan) {
      patchState(store, state => ({ ...state, selectedPlan: plan }));
    },
    setAnually(value: boolean) {
      patchState(store, state => ({ ...state, isAnually: value }));
    },
    setPlanAndAnually(plan: Plan, value: boolean) {
      patchState(store, state => ({
        ...state,
        selectedPlan: plan,
        isAnually: value,
      }));
    },
  }))
);
