import { Component, inject, signal } from '@angular/core';
import { Router } from '@angular/router';
import { injectQuery } from '@tanstack/angular-query-experimental';
import {
  Plan,
  PlanList,
  PlanLoading,
  PlanService,
  PlanStore,
  QUERY_KEY_PLAN,
} from '@trueleap/plan';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'landing-page-pricing',
  imports: [PlanLoading, PlanList],
  standalone: true,
  template: `
    <div class="pricing-container">
      @if (plans.isPending()) {
        <plan-loading></plan-loading>
      } @else if (plans.isError()) {
      } @else {
        @if (plans.data(); as plans) {
          <plan-list
            (selectedPlan)="onPlanSelected($event)"
            [plans]="plans"
            (annualSelect)="setAnually($event)"></plan-list>
        }
      }
    </div>
  `,
  styles: [
    `
      .pricing-container {
      }
    `,
  ],
})
export class LandingPagePricing {
  planService = inject(PlanService);
  isAnnual = signal<boolean>(true);
  plans = injectQuery(() => ({
    queryKey: [QUERY_KEY_PLAN],
    queryFn: () => lastValueFrom(this.planService.fetchAllPlans()),
    refetchOnWindowFocus: false,
  }));
  router = inject(Router);
  planStore = inject(PlanStore);

  setAnually(value: boolean) {
    this.isAnnual.set(value);
  }

  onPlanSelected(plan: Plan) {
    this.planStore.setPlanAndAnually(plan, this.isAnnual());

    this.router.navigate(['auth', 'sign-up'], {
      queryParams: { planId: plan.id },
    });
  }
}
