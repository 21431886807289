import { NgFor, SlicePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { RouterLinkWithHref } from '@angular/router';
import { injectQuery } from '@tanstack/angular-query-experimental';
import { WebinerService } from '@trueleap-plus/features/webiner';
import { WebinerCardComponent } from '@trueleap/ui-kit';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'landing-events-and-courses',
  standalone: true,
  imports: [WebinerCardComponent, NgFor, RouterLinkWithHref, SlicePipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="courses-container">
      <h3 class="mat-headline-medium">VIBRANT VOYAGES</h3>
      <h1 class="mat-display-medium">Dynamic Courses & Events</h1>

      <div class="course-cards">
        @if (webinerQueries.isPending()) {
          <div>loading...</div>
        } @else if (webinerQueries.isError()) {
        } @else {
          @if (webinerQueries.data(); as webiners) {
            @for (webiner of webiners | slice: 0 : 4; track webiner) {
              <trlp-webiner-card [WebinerDetails]="webiner"></trlp-webiner-card>
            }
          }
        }
      </div>
    </div>
  `,
  styles: [
    `
      @use '@angular/material' as mat;
      @import 'theme';
      .courses-container {
        max-width: 88.25rem;
        margin: 0 auto;
        padding: 5rem 0;

        display: flex;
        flex-direction: column;
        align-items: center;

        .mat-headline-medium {
          color: mat.get-theme-color($trueleap-theme, primary);
        }

        .mat-display-medium {
          color: mat.get-theme-color($trueleap-theme, primary);
        }

        .course-cards {
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
          grid-template-rows: 1fr;
          max-width: 100%;
          grid-gap: 1rem;
        }
      }
    `,
  ],
})
export class LandingEventsAndCourses {
  eventService = inject(WebinerService);

  webinerQueries = injectQuery(() => ({
    queryKey: ['events'],
    queryFn: () => lastValueFrom(this.eventService.fetchMarketplaceEvents()),
  }));
}
