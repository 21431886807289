import { Component } from '@angular/core';
import { HeroComponent } from './hero.component';
import { LandingCreatorSection } from './landing-creator';
import { LandingEventsAndCourses } from './landing-events-courses';
import { LandingKnowUs } from './landing-know-us';
import { LandingPagePricing } from './landing-page-pricing';

@Component({
  selector: 'landing-home',
  standalone: true,
  imports: [
    LandingPagePricing,
    HeroComponent,
    LandingKnowUs,
    LandingCreatorSection,
    LandingEventsAndCourses,
  ],
  template: `
    <div class="landing-home-container">
      <div class="hero-container">
        <landing-hero></landing-hero>
      </div>
      <div class="know-user-container">
        <landing-know-us></landing-know-us>
      </div>
      <div class="creator-section">
        <landing-creator-section></landing-creator-section>
      </div>
      <div class="events-and-courses">
        <landing-events-and-courses></landing-events-and-courses>
      </div>
      <div class="workshop-section"></div>
      <div class="testimonials-section"></div>
      <div class="pricing-container">
        <landing-page-pricing></landing-page-pricing>
      </div>
    </div>
  `,
  styles: [
    `
      .landing-home-container {
        .hero-container {
          background: radial-gradient(
            307.77% 73.75% at 94.17% 50.01%,
            #ebe5f1 0%,
            #ffeddf 100%
          );
        }

        .creator-section {
        }

        .events-and-courses {
          background: radial-gradient(
            307.77% 73.75% at 94.17% 50.01%,
            #ebe5f1 0%,
            #ffeddf 100%
          );
        }
        .pricing-container {
          padding-top: 1rem;
          padding-bottom: 4rem;
        }
      }
    `,
  ],
})
export class LandingHome {}
